import CryptoJS from 'crypto-js';

const aesKey = process.env.REACT_APP_CLIENT_AES_SECRET_KEY;
var parsedSecret = CryptoJS.enc.Utf8.parse(aesKey ? aesKey.toString() : '');
var base64EncodedKey = encodeText(parsedSecret);
export const cryptoKey = CryptoJS.enc.Base64.parse(base64EncodedKey);

export function encryptText(data: String, cryptoKey: any): String {
  var encrypted = CryptoJS.AES.encrypt(data.toString(), cryptoKey, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });

  var encryptedBase64Text = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
  return encryptedBase64Text;
}

export function encodeText(text: CryptoJS.lib.WordArray): string {
  var encodedText = CryptoJS.enc.Base64.stringify(text);
  return encodedText;
}

export function encryptObj(data: Object): Object {
  Object.keys(data).forEach(function(key) {
    data[key] = encryptText(data[key], cryptoKey);
  });
  return data;
}

export function decryptText(encryptedText: String, cryptoKey: any): String {
  var decrypted = CryptoJS.AES.decrypt(encryptedText.toString(), cryptoKey, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });

  var decryptedUtf8Text = decrypted.toString(CryptoJS.enc.Latin1);
  return decryptedUtf8Text;
}

export function decryptObj(data: Object): Object {
  Object.keys(data).forEach(function(key) {
    data[key] = decryptText(data[key], cryptoKey);
  });
  return data;
}
