import { FwdAPIInstance } from '../configFwdApi';
import { VinovaAPIInstance } from '../configVinovaApi';
import { decryptObj, encryptObj } from 'helpers/encrypt';

export const policyDetailApi = async (data: any) => {
  try {
    const res = await FwdAPIInstance.post(
      `policy-services/policy-detail`,
      data
    );
    return res;
  } catch (err) {
    return { response: err.response, error: true };
  }
};

export const paymentDetailApi = async (data: any) => {
  try {
    data = encryptObj(data);
    var res = await FwdAPIInstance.post(
      `ods/default/secure/SP_IS_GET_POLICYPAYMENTINFO`,
      data
    );
    res['policy-paymentinfo'][0] = decryptObj(res['policy-paymentinfo'][0]);
    return res;
  } catch (err) {
    return { response: err.response, error: true };
  }
};

export const businessDateApi = async (data: any) => {
  try {
    const res = await FwdAPIInstance.post(
      `policy-services/business-date2`,
      data
    );
    return res;
  } catch (err) {
    return { response: err.response, error: true };
  }
};

export const insurancetypeApi = async (data: any) => {
  try {
    const res = await VinovaAPIInstance.get(
      `api/policy/payment-portal/plancode-insurancetype?PlanCode=${data}`
    );
    return res;
  } catch (err) {
    return { response: err.response, error: true };
    // Promise.reject(err);
  }
};

export const maintenanceApi = async () => {
  try {
    const res = await VinovaAPIInstance.get(`api/Policy/check-maintenance-up`);
    return res;
  } catch (err) {
    return { response: err.response, error: true };
  }
};
