import { takeLatest, call, put } from 'redux-saga/effects';
import {
  SET_DRAGON_BILLING,
  GET_DRAGON_BILLING,
} from 'redux/reducers/payment/actionTypes';
import {
  SET_OPEN_TOP_ALERT,
  SET_CONTENT_TOP_ALERT,
} from 'redux/reducers/global/actionTypes';
import { dragonBill } from 'services/payment/paymentApi';

function* requestPaymenDragonBill(action) {
  const result = yield call(dragonBill, action.body, action.refno);
  if (result && result.response && result.error) {
    const topAlertContent = {
      iconType: 'la la-exclamation-circle',
      message: `Sorry, ${result.response.status} ${result.response.statusText}`,
      iconClose: 'la la-times',
    };
    yield put({ type: SET_CONTENT_TOP_ALERT, topAlertContent });
    yield put({ type: SET_OPEN_TOP_ALERT });
    return;
  }
  if (!!result) {
    yield put({ type: SET_DRAGON_BILLING, result });
  }
}
function* getDragonBill() {
  yield takeLatest(GET_DRAGON_BILLING, requestPaymenDragonBill);
}

export default getDragonBill;
