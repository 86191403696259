import { FwdAPIInstance } from '../configFwdApi';
import { VinovaAPIInstance } from '../configVinovaApi';
import { initial } from 'redux/reducers/payment/index';
import { cryptoKey, decryptText, encryptText } from 'helpers/encrypt';
const queryString = require('query-string');

const axios = require('axios');

export const paymentDueApi = async (data: any) => {
  try {
    const res = await FwdAPIInstance.post(`policy-services/payment-due`, data);
    if (res['payment-due'][0].error) {
      //Promise.reject(res);
      return { response: res['payment-due'][0].error, error: true };
    } else {
      return res;
    }
  } catch (err) {
    return { response: err.response, error: true };
    // return Promise.reject(err);
  }
};

export const initialPaymentDueApi = async (data: any) => {
  try {
    const res = await FwdAPIInstance.post(
      `policy-services/initialpayment-due`,
      data
    );
    if (res['initialpayment-due'][0].error) {
      //Promise.reject(res);
      return { response: res['initialpayment-due'][0].error, error: true };
    } else {
      return res;
    }
  } catch (err) {
    return { response: err.response, error: true };
    // return Promise.reject(err);
  }
};

export const dragonTokenId = async (data: any) => {
  try {
    const res = await VinovaAPIInstance.post(
      `api/Transaction/payment-portal/create-transaction`,
      data
    );
    console.log('res', res);
    const tokenId = res['TokenId'];
    console.log('tokenId: ', tokenId);
    console.log('process.env.NODE_ENV: ', process.env.NODE_ENV);
    let dragonpayUrl = '';
    if (process.env.REACT_APP_ENV === 'development') {
      dragonpayUrl = `https://test.dragonpay.ph/Pay.aspx?tokenid=${tokenId}&procid=cc&ccy=PHP`;
    } else {
      dragonpayUrl = `https://gw.dragonpay.ph/Pay.aspx?tokenid=${tokenId}&procid=cc&ccy=PHP`;
    }
    window.location.replace(dragonpayUrl);
    localStorage.setItem('transactionId', res['TransactionId']);
    return res;
  } catch (err) {
    return { response: err.response, error: true };
    // return Promise.reject(err);
  }
};

export const dragonBill = async (data: any, refno: string) => {
  const dragonBill = initial['dragonBill'];
  data = encryptText(data, cryptoKey)
    .toString()
    .replace(/\//g, '_');
  if (refno) {
    try {
      console.log('data', data);
      const res = await VinovaAPIInstance.get(
        `api/Transaction/payment-portal/get-transaction/${data}`
      );
      const payInfo = decryptText(res['DESCRIPTION'], cryptoKey);
      const _payInfo = payInfo != null ? payInfo.split('!').join('&') : '';
      const parsedHash = queryString.parse(_payInfo);
      dragonBill.policyNum = parsedHash.policyNum;
      dragonBill.owner = parsedHash.owner;
      dragonBill.productName = parsedHash.productName;
      dragonBill.payType = parsedHash.payType;
      dragonBill.clientCode = parsedHash.clientCode;
      dragonBill.initialAmount = parsedHash.initialAmount;
      dragonBill.refNo = refno;
      dragonBill.settleDate = decryptText(
        res['CREATEDON'],
        cryptoKey
      ).toString();
      dragonBill.amount = decryptText(res['AMOUNT'], cryptoKey).toString();
      dragonBill.currency = decryptText(res['CURRENCY'], cryptoKey).toString();
      return dragonBill;
    } catch (err) {
      //decrypt here
      return { response: err.response, error: true };
    }
  }
};
